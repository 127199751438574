<template>
  <div class="px-0 sm:px-4 py-0 sm:py-2 sm:grid sm:grid-cols-2">
    <ClientOnly>
      <div v-if="selectedVariant?.images.length > 0" class="sm:col-span-2 sm:grid hidden sm:grid-cols-2 gap-2.5">
        <div v-for="(image, idx) in selectedVariant?.images" :key="`tmb-${idx}`">
          <template v-if="productImageZoomType === '2'">
            <NuxtImg
                :src="getImage(image?.medium)"
                width="490"
                height="720"
                fit="cover"
                loading="lazy"
                format="webp"
                :placeholder="[50, 50, 93, 3]"
                :quality="98"
                :alt="selectedVariant?.title"
                class="object-contain"
                @click="open(image)"
            />
          </template>
          <template v-else>
            <Zoom
                :src="getImage(image?.medium)"
                zoomType="hover"
                width=490
                height=720
                :zoomSrc="getImage(image?.original)"
                fullscreen-on-mobile
                :idx="idx"
            >
              <NuxtImg
                  :src="getImage(image?.medium)"
                  width="1200"
                  height="1800"
                  fit="cover"
                  loading="lazy"
                  format="webp"
                  :alt="selectedVariant?.title"
                  :placeholder="[50, 50, 93, 3]"
                  :quality="98"
                  class="object-contain"
              />
            </Zoom>
          </template>
        </div>
      </div>
      <ProductModal/>
      <div class="sm:hidden w-full">
        <SwiperImages/>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { themeComponents } = storeToRefs(storeConfig)

const productImageZoomType = unref(themeComponents)?.product_image_zoom_component

const openProductModal = useState("openProductModal", () => false);
const {selectedVariant, product} = await useProduct();
const {setSelectedImage} = useZoom();

const activeProducts = ref(() => selectedVariant.value?.images[0]);
const setActiveProducts = (product) => {
  activeProducts.value = product;
};

const open = (image) => {
  openProductModal.value = true
  setSelectedImage(image?.original);
}

watchEffect(() => {
  setActiveProducts(selectedVariant.value?.images[0]);
});
</script>
